<div class="contactUS-container">
  <div class="escrito">
    <h1>Novo Look</h1>
    <h2>Contactanos y pide tu cita</h2>
    <p>Si quieres más información sobre nuestros servicios de <strong>Peluquería
      y Belleza</strong> o solicitar una cita previa no dudes en visitarnos para aclarar tus dudas o también
      puedes contactarnos a través de nuestro <strong>teléfono</strong> o <strong>whatsapp</strong> y también puedes seguirnos en nuestra
       página de <strong>facebook</strong> y <strong>instagram</strong> donde puedes encontrar nuestras últimas noticias!</p>
      <div class="datos" *ngFor="let item of datosDeContactos" >
        <p class="description"><i class="{{item.icon}}"></i> {{item.tipoDeDatos}}</p>
        <p class="data"><a href="{{item.link}}">{{item.datos}}</a></p>
      </div>
      <div class="datos">
        <p class="description"><i class="far fa-clock"></i> Horarios</p>
        <p class="diasHoras">Días: Martes a Sabados <br> Mañana: 9:30 - 13:00 <br>Tarde: 15:00 - 20:00</p>
      </div>
  </div>
  <iframe class="datos-container" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11832.094286761043!2d-7.9578816!3d42.1497824!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa7307783ec1ba771!2sPeluqueria%20novo%20look!5e0!3m2!1ses!2ses!4v1600334402078!5m2!1ses!2ses" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
  <a class="btn-down-next-section" mat-mini-fab href="#galeria"><mat-icon>keyboard_arrow_down</mat-icon></a>
</div>
