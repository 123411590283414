import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {

  windowScrolled: boolean;
  constructor(@Inject(DOCUMENT) private document: Document) {}

  @HostListener('window:scroll', [])
  // tslint:disable-next-line: typedef
  onWindowScroll() {
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.windowScrolled = true;
      }
     else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
          this.windowScrolled = false;
      }
  }
  // tslint:disable-next-line: typedef
  scrollToTop() {
      // tslint:disable-next-line: typedef
      (function smoothscroll() {
          // tslint:disable-next-line: prefer-const
          let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              /* entre mas pequeño el numero en que se divida currentScroll ira mas rapido */
              /* if you set to divide currentScroll to a lower number its go faster */
              window.scrollTo(0, currentScroll - (currentScroll / 1.2));
          }
      })();
  }
  // tslint:disable-next-line: typedef
  ngOnInit() {}

}
