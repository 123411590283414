<div class="footer">
 <div class="horario">
   <h1>Horario</h1>
   <table>
     <tr>
       <th>Días</th>
       <th>Mañana</th>
       <th>Tarde</th>
     </tr>
     <tr *ngFor="let item of datosDeHorarios">
      <td>{{item.days}}</td>
      <td>{{item.morning}}</td>
      <td>{{item.afternoon}}</td>
     </tr>
   </table>
 </div>
 <div class="locacion">
   <h1>Locacion</h1>
   <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11832.094286761043!2d-7.9578816!3d42.1497824!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa7307783ec1ba771!2sPeluqueria%20novo%20look!5e0!3m2!1ses!2ses!4v1600420902285!5m2!1ses!2ses" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
   <p> Rúa da Encarnación, 17 <br> Celanova, Ourense </p>
 </div>
 <div class="menu">
   <h1>Menu</h1>
   <p *ngFor="let item of datosDemenu"><a class="{{item.class}}" mat-flat-button href="{{item.src}}"><i class="{{item.icon}}"></i> {{item.dato}}</a></p>
 </div>
 <div class="contacto">
   <h1>Contacto</h1>
   <p>Telefono: 988-43-19-33</p>
   <p>Email: hola@peluquerianovolook.com</p>
   <p>WhatsApp: 647-98-40-78</p>
     <a mat-flat-button href="https://es-es.facebook.com/pages/category/Hair-Salon/Peluqueria-Novo-Look-558953924114616/"><i class="fab fa-facebook"></i></a>
     <a mat-flat-button href="https://www.instagram.com/peluquerianovolook/?hl=es"><i class="fab fa-instagram"></i></a>
 </div>
</div>
