import {ICarouselItem} from './componentes/carousel/Icarousel-item.metadata';

export const CAROUSEL_DATA_ITEMS: ICarouselItem[] = [
    {
        id: 1,
        title: {
            first: 'TITULO',
            second: 'Principal'
        },
        subtitle: 'Esto es una gran descripción',
        link: '/',
        image: '.././assets/images/novo-look-peluqueria-celanova-carousel.jpg'
    },
    {
        id: 2,
        title: {
            first: 'TITULO',
            second: 'Segundo'
        },
        subtitle: 'Esto es una gran descripción',
        link: '/',
        image: '.././assets/images/novo-look-servicio-de-depilacion-laser-celanova-carousel.jpg'
    },
    {
        id: 3,
        title: {
            first: 'TITULO',
            second: 'Tercero'
        },
        subtitle: 'Esto es una gran descripción',
        link: '/',
        image: '.././assets/images/novo-look-servicio-de-estetica-celanova-carousel.jpg'
    },
    {
        id: 4,
        title: {
            first: 'TITULO',
            second: 'Cuarto'
        },
        subtitle: 'Esto es una gran descripción',
        link: '/',
        image: '.././assets/images/novo-look-servicio-de-solarium-celanova-carousel.jpg'
    },
];
