import { ContactData } from './../../contactos';
import { servicioData } from './../../servicios';
import { horarioData } from './../../horarios';
import { menuData } from './../../menu';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  datosDeContactos = ContactData;
  datosDeServicios = servicioData;
  datosDeHorarios = horarioData;
  datosDemenu = menuData;

  constructor() { }

  ngOnInit(): void {
  }

}
