<div class="aboutUs-container-1">
  <div class="escrito">
    <h1>Novo Look</h1>
    <h2>Peluquería y Salón en Celanova</h2>
    <p>
      Con más de 10 años de experiencia, en <strong>Novo Look</strong> en <strong>Celanova</strong> nos dedicamos al trabajo de <strong>Peluquería</strong> y <Strong>Salón de belleza</Strong>
      a un nivel profesional para ofrecerte los mejores servicios tanto para el día a día como para <strong>eventos, fiestas, bodas</strong> y todos los retos que nos propongas.
      Nuestros servicios cuentan con la mayor preparacion y dedicación de nuestro equipo humano los <strong>mejores productos</strong>
      para el tratamiento del cabello y la belleza.
    </p>
    <p>
      Ofrecemos un amplio catálogo de servicios de peluquería y estética desde nuestros básicos,
      <strong>peinados, cortes de pelo, color, Solarium, Manicura, Pedicura, Ceras y Tatuajes... </strong>
      que ejecutamos con amplia experiencia para atender a todas tus necesidades.
    </p>
    <p>
      Nuestro ímpetu de seguir adelante, mejorar, ofrecer el mejor servicio y valores a nuestros clientes día a día es lo que empuja a nuestro equipo a estar siempre
       contigo y ofrecerte las últimas tendencias del mundo de la belleza.
    </p>
  </div>
  <div class="img-container"></div>
</div>
<div class="aboutUs-container-2">
  <div class="escrito">
    <h1>Peluquería</h1>
    <h2>Peluquería y Salón en Celanova</h2>
    <p>
      En <strong>Novo Look</strong> la <strong>Peluquería</strong> es nuestra pasión  y ayudar a nuestros clientes en el dia a dia
      a un nivel profesional con nuestros servicios de <strong>tratamientos reestructurales capilares, cortes, mechas, permanentes, coloración</strong>
      y estamos en constante aprendizaje para ofrecerte lo ultimo en estetica y usando los mejores productos para obtener la mejor calidad que necesita  tu cabello
      y que lograra resultados que te sorprenderán para el tratamiento del cabello.
    </p>
  </div>
  <div class="img-container"></div>
</div>
<div class="aboutUs-container-3">
  <div class="escrito">
    <h1>Nuestro Centros de bronceado</h1>
    <h2>Tu solariums en Celanova</h2>
    <p>
      En <strong>Novo Look</strong> tenemos un <strong>Centros de bronceado</strong> con la mas alta profesionalidad  y seguridad para ofrecerte las mejores
      sesiones en nuestro <Strong>solarium</Strong> con una variedad bonos de sesiones a los mejores precios para atender a todas tus necesidades.
    </p>
    <h2>Bonos de solarium</h2>
    <table>
      <tr>
        <th>Nº de seciones</th>
        <th>precio</th>
      </tr>
      <tr>
        <td>6 seciones</td>
        <td>25€</td>
      </tr>
      <tr>
        <td>12 seciones</td>
        <td>45€</td>
      </tr>
      <tr>
        <td>18 seciones</td>
        <td>60€</td>
      </tr>
    </table>
  </div>
  <div class="img-container"></div>
  <a class="btn-down-next-section" mat-mini-fab href="#contacto"><mat-icon>keyboard_arrow_down</mat-icon></a>
</div>
