import { AvisoLegalComponent } from './componentes/aviso-legal/aviso-legal.component';
import { HeroComponent } from './componentes/hero/hero.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path: '', component: HeroComponent},
  {path: 'legal', component: AvisoLegalComponent},
  {path: '**', component: HeroComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
