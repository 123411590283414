<mat-toolbar class="nav">
  <mat-toolbar-row>
    <span class="spacer"></span>
    <span class="logo">
      <a class="logo-links" mat-flat-button href="#home">Novo Look</a>
    </span>
    <span class="spacer"></span>
    <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"></button>
    <mat-icon>menu</mat-icon>
    </button> -->
  </mat-toolbar-row>
  <mat-toolbar-row class="desk-nav-section">
    <span class="spacer"></span>
    <a class="desk-links" mat-flat-button href="#home"><i class="fas fa-home"></i>inicio</a>
    <a class="desk-links" mat-flat-button href="#nosotros"><i class="fas fa-address-card"></i>Sobre nosotros</a>
    <a class="desk-links" mat-flat-button href="#contacto"><i class="far fa-address-book"></i>Contacto</a>
    <a class="desk-links" mat-flat-button href="#galeria"><i class="far fa-images"></i>Galeria</a>
    <span class="spacer"></span>
  </mat-toolbar-row>
  <mat-toolbar-row class="cell-nav-section">
    <a class="cell-links" mat-flat-button href="#home">inicio</a>
    <a class="cell-links" mat-flat-button href="#nosotros">Sobre nosotros</a>
    <a class="cell-links" mat-flat-button href="#contacto">Contacto</a>
    <a class="cell-links" mat-flat-button href="#galeria">Galeria</a>
  </mat-toolbar-row>
  <!-- <mat-toolbar-row>
    <p style="text-align: center;">Rúa da Encarnación - 17 Celanova, Ourense<br>Tel: 988-43-19-33</p>
  </mat-toolbar-row> -->
</mat-toolbar>
