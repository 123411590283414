<div class="gallery-container">
  <h1>Galeria</h1>
  <mat-tab-group>
    <mat-tab label="Salon">
      <div class="img-gallery" id="salon">
        <a href="../../../assets/img/gallery/salon/novo-look-peluqueria-y-salon.jpg" class="img-1"></a>
        <a href="../../../assets/img/gallery/salon/novo-look-peluqueria-y-salon-ceras.jpg" class="img-2"></a>
        <a href="../../../assets/img/gallery/salon/novo-look-peluqueria-y-salon-manucura.jpg" class="img-3"></a>
        <a href="../../../assets/img/gallery/salon/novo-look-peluqueria-y-salon-pedicura.jpg" class="img-4"></a>
        <a href="../../../assets/img/gallery/salon/novo-look-peluqueria-y-salon-solarium.jpg" class="img-5"></a>
        <a href="../../../assets/img/gallery/salon/novo-look-peluqueria-y-salon-tratado-de-cabello.jpg" class="img-6"></a>
    </div>
    </mat-tab>
    <mat-tab label="Productos">
      <div class="img-gallery" id="productos">
        <a href="../../../assets/img/gallery/productos/novo-look-peluqueria-y-salon-colores-para-cabello.jpg" class="img-1"></a>
        <a href="../../../assets/img/gallery/productos/novo-look-peluqueria-y-salon-productos-de-makeup.jpg" class="img-2"></a>
        <a href="../../../assets/img/gallery/productos/novo-look-peluqueria-y-salon-productos-para-belleza.jpg" class="img-3"></a>
        <a href="../../../assets/img/gallery/productos/novo-look-peluqueria-y-salon-productos-para-cabello.jpg" class="img-4"></a>
        <a href="../../../assets/img/gallery/productos/novo-look-peluqueria-y-salon-productos-para-cuidado-de-cabello.jpg" class="img-5"></a>
        <a href="../../../assets/img/gallery/productos/novo-look-peluqueria-y-salon-productos-para-el-cuidado-del-hombre.jpg" class="img-6"></a>
    </div>
  </mat-tab>
    <mat-tab label="Trabajos">
      <div class="img-gallery" id="trabajos">
        <a href="../../../assets/img/gallery/trabajos/novo-look-peluqueria-celanova-cortes-de-pelo.jpg" class="img-1"></a>
        <a href="../../../assets/img/gallery/trabajos/novo-look-peluqueria-celanova-peinados-bodas.jpg" class="img-2"></a>
        <a href="../../../assets/img/gallery/trabajos/novo-look-peluqueria-celanova-peinados-eventos-y-bodas.jpg" class="img-3"></a>
        <a href="../../../assets/img/gallery/trabajos/novo-look-peluqueria-celanova-peinados-eventos.jpg" class="img-4"></a>
        <a href="../../../assets/img/gallery/trabajos/novo-look-peluqueria-celanova-peinados.jpg" class="img-5"></a>
        <a href="../../../assets/img/gallery/trabajos/novo-look-peluqueria-celanova-tintes.jpg" class="img-6"></a>
    </div>
  </mat-tab>
    <mat-tab label="Peinados y tintes">
      <div class="img-gallery" id="peinados">
        <a href="../../../assets/img/gallery/peinados/novo-look-peluqueria-celanova-cortes-de pelo.jpg" class="img-1"></a>
        <a href="../../../assets/img/gallery/peinados/novo-look-peluqueria-celanova-peinados-bodas.jpg" class="img-2"></a>
        <a href="../../../assets/img/gallery/peinados/novo-look-peluqueria-celanova-peinados-eventos-y-bodas.jpg" class="img-3"></a>
        <a href="../../../assets/img/gallery/peinados/novo-look-peluqueria-celanova-peinados-eventos.jpg" class="img-4"></a>
        <a href="../../../assets/img/gallery/peinados/novo-look-peluqueria-celanova-peinados.jpg" class="img-5"></a>
        <a href="../../../assets/img/gallery/peinados/novo-look-peluqueria-celanova-tintes.jpg" class="img-6"></a>
    </div>
  </mat-tab>
  </mat-tab-group>
</div>
